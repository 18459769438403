<template>
    <div class="businessWarp">
        <div class="business">
            <!-- <a href="/business" class="more">更多></a>
            <el-tabs v-model="pgData.type" @tab-click="handleClick">
                <el-tab-pane 
                    v-for="(item,index) in ggType"
                    :key="index"
                    :label="item.name" 
                    :name="item.type">
                </el-tab-pane>
                
            </el-tabs> -->
            <h2 style="color:#61ae6a">交易公告</h2>
             <div class="search-bar">
                
                <div class="search-item">
                    <div class="label">公告类型：</div>
                    
                    <div class="list">
                        <div 
                        class="item" 
                        v-for="(item, index) in proTypes" 
                        :class="[item.value===gglx ? 'active' : '']" 
                        @click="tabgglx(item.value)"
                        :key="index"
                        >
                            {{item.label}}
                        </div>
                    </div>
                </div>

                <div class="search-item">
                    <div class="label">项目区域：</div>
                    
                    <div class="list">
                        <div 
                        class="item" 
                        v-for="(item, index) in cityList" 
                        :class="[item.cityid===pgData.citycode ? 'active' : '']" 
                        :key="index"
                        @click="updateModel('citycode', item.cityid,'region',item.name)"
                        >
                            {{item.name}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="listinfo">
                <ul v-if="gglx=='zbxm'">
                    <li 
                        v-for="(item,index) in tableData"
                        :key="index">
                            <a @click="hrefBuilder(item)">
                                {{item.ggname}}
                            </a>
                            <span>
                                {{item.ggfbtime}}
                            </span>
                    </li>
                </ul>
                
                <my-pagination
                    ref="page"
                    v-if="gglx=='zbxm'"
                    class="page"
                    :action="tableUrl"
                    v-model="tableData"
                    :search="pgData"
                    :loading.sync="loadingController"
                ></my-pagination>

                <ul v-if="gglx=='cqxm'">
                    <li 
                        v-for="(item,index) in tableData"
                        :key="index">
                            <a @click="hrefBuilder(item)">
                                {{item.title}}
                            </a>
                            <span>
                                {{item.addtime}}
                            </span>
                    </li>
                </ul>
                <my-pagination
                    ref="page"
                    v-if="gglx=='cqxm'"
                    class="page"
                    :action="tableUrlcqxm"
                    v-model="tableData"
                    :search="pgDatacqxm"
                    :loading.sync="loadingController"
                ></my-pagination>
            </div>
        </div>
    </div>
    
</template>

<script>
import CONFIG from '@config/config';

export default {
    components: {
        
    },
    data() {
        return {
            tableData:[],
            pgData: {
                type: 'all',
                leixing: '全部',
                citycode: CONFIG.head.citycode,
                featurecode:CONFIG.head.planname,
                searchtype: 'ggname',
                searchcontent: '',
                pagesize:'10'
            },

            pgDatacqxm:{
                sortname: 'addtime',
                sortorder: 'desc',
                pageindex: '1',
                pagesize: '10',
                noticetype:'',
                region:'',
                title: '',
                shbj: '9',
                featurecode:CONFIG.head.planname
            },
            
            proTypes: [
                {
                    value: 'zbxm',
                    label: '招标项目'
                },
                {
                    value: 'cqxm',
                    label: '产权项目'
                },
            ],
            gglx:'zbxm',
            cityList: [],
            loadingController: false,
            tableUrl:`${this.$store.state.api.webUrl}/index/information/getjyinfopg.json`,
            tableUrlcqxm:'/sys/notic/list'
        };
    },
    methods: {
        
        queryCity() {
            this.$get(`${this.$store.state.api.webUrl}/index/information/getcitylist.json`, {
                parentid: CONFIG.head.citycode,
            }, data => {
                this.cityList = data;
                this.cityList.unshift({
                    name: '全部', 
                    cityid: CONFIG.head.citycode,
                });
            });
        },
        handleClick(){
            this.$refs.page.queryData();
        },
        hrefBuilder(item) {
            if(this.gglx=='zbxm'){
                var search = toSearch({
                    ggcode: item.ggcode,
                    categoryid: item.ggtype
                });

                if (/SD/.test(item.ggcode)) {
                    window.location.href =  `/business/detail-sd${search}`
                    return
                }
                window.location.href = `/business/detail${search}&featurecode=${this.pgData.featurecode}`
                return
            }else{
                window.open('http://nccqpaimai.xtnccq.com/#/notice/detail?id='+item.rowguid+'&featurecode='+CONFIG.head.planname)
            }
            
        },

        tabgglx(value){
            this.gglx = value;
            this.$refs.page.queryData();
        },
        updateModel(key, value,key1,value1) {
            this.pgData[key] = value;
            if(value1=='全部'){
                this.pgDatacqxm[key1] = '';
            }else{
                this.pgDatacqxm[key1] = value1;
            }
            
            this.$refs.page.queryData();
        },
    },
    mounted() {
        this.queryCity();
    },
};
</script>

<style lang="scss" scoped>
.businessWarp{
    margin-top: 20px;
    padding: 20px 30px;
    border: solid 1px #dedede;
}
.business{
    position: relative;
    h2{
        text-align: center;
        font-size: 20px;
    }
    .more{
        position: absolute;
        right: 10px;
        top: 5px;
        color: #666;
        z-index: 10;
    }
    .title{
        color: #666666;
    }

    .divider{
        margin: 10px 0 20px;
    }
    
    .el-tabs__item.is-active{
        color: #fff !important;
    }
    .listinfo{
        height: 430px;
        overflow: hidden;


        li{
            line-height: 36px;
            overflow: hidden;

            a{
                color: #666;
                float: left;
                width: 500px;
                height: 28px;
                overflow: hidden;
                
                &:hover{
                    color:#61ae6a
                }
            }
            span{
                float: right;
                color: #666;
            }
        }
    }
   .cont_botton{
        display:block;
        width:118px;
        height:44px;
        background: #61ae6a;
        line-height:44px;
        text-align:center;
        margin:0 auto;
        color:#fff;
        margin-top:30px;
    }
    .cont_botton:hover{
        color:#fff;
        opacity: 0.9;
    }

    .search-bar{
        cursor: default;

        .search-item{
            overflow: hidden;
            line-height: 1em;
            margin-bottom: 10px;
            .label{
                float: left;
                color: #61ae6a;
                margin: 6px 0;
                margin-right: -65px;
                padding: 5px;
            }

            .list{
                margin-left: 65px;
                overflow: hidden;

                .item{
                    float: left;
                    color: #7a7a7a;
                    margin: 6px 0;
                    margin-left: 30px;
                    padding: 5px;
                    cursor: pointer;
                    transition: all .3s;

                    :not(.active):hover {
                        background: #61ae6a;
                    }

                    &.active{
                         background: #61ae6a;
                         color: white;
                    }
                }
            }
        }
    }

    .my-pagination-con{
        margin-top: 30px;
    }
}
</style>

<style>
.business .el-tabs__item{
    font-weight: 600 !important;
    font-size: 16px;
}
</style>