<template>
    <div class="index content">
        <banner></banner>
        <!-- <enters></enters> -->
        <div class="login">
            <el-row :gutter="20">
                <el-col :span="4">
                    <a style="background:#C1B65A" :href="head.dljgLoginUrl" target="_blank">代理机构登录</a>
                </el-col>
                <el-col :span="4">
                    <a style="background:#5AA2C1" :href="head.yezhuLoginUrl" target="_blank">采购人登录</a>
                </el-col>
                <el-col :span="4">
                    <a style="background:#56BEA2" :href="head.tbrLoginUrl" target="_blank">投标用户登录</a>
                </el-col>
                <el-col :span="4">
                    <a style="background:#869ED1" :href="head.jmrLoginUrl" target="_blank">竞买人登录</a>
                </el-col>
                <el-col :span="4">
                    <a style="background:#9CBE65" :href="head.zjLoginUrl" target="_blank">评标专家登录</a>
                </el-col>
                <!-- <el-col :span="4">
                    <a style="background:#77D7B9" href="/register" target="_blank">注册</a>
                </el-col> -->
            </el-row>
        </div>
        <business></business>
        
    </div>
</template>

<script>
import Banner from './index/sections/banner';
import Enters from './index/sections/enters';
import Business from './index/sections/business';
import CONFIG from '@config/config';

import appNode from '@js/app-node';

export default {
    components: {
        Banner,
        Enters,
        Business,
    },
    
    data: () => ({
        head:CONFIG.head,
    }),
    methods: {
        
    },
    mounted() {
        
    }
}
</script>

<style lang="scss" scoped>
    .login{
        margin: 30px 0;
        margin-left: 150px;
        .el-col{
            text-align: center;
            height: 60px;
            line-height: 60px;
            
            a{ display: block; color: #fff; }
        }
    }
</style>
