<template>
    <el-row :gutter="5">
        <el-col :span="16">
            <section class="banner">
                <el-carousel class="banner_img" indicator-position="none">
                    <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                        <a :href="item.linkurl" target="_blank">
                            <img :src="item.linkimg">
                        </a>
                    </el-carousel-item>
                </el-carousel>
        
            </section>
        </el-col>
        <el-col :span="8">
            <news style="margin-top:5px"></news>
        </el-col>
    </el-row>
</template>

<script>
import CONFIG from '@config/config';
import appNode from '@js/app-node';
import News from './news';


export default {
    components: {
        News,
    },
    data() {
        return {
            title: CONFIG.head.title,

            hotList: ['政府采购', '企业采购'],
            bannerList:[],
            dataList: [],
        };
    },
    methods: {
 
    },
    mounted() {
        var that = this;

        // 获取轮播图
        this.$get(`${this.$store.state.api.webUrl}/index/information/getcarouselpic.json`,  {
            featurecode:CONFIG.head.planname,
        }, data => {
          
            if(data.length>0){
                this.bannerList = data;
            }else{
                this.bannerList = [{
                    linkimg:'/images/banner1.jpg',
                    linkurl:''
                }];
            }
            
        })

    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .banner{
        box-sizing: border-box;
        height: 300px;
        background-size: cover;
        background-position: center cetner;
        position: relative;
        margin-top: 5px;
        .banner_img{
            height: 300px;
            width: 100%;

            ::v-deep .el-carousel__container{
                height: 300px;
            }
            img{ width: 100%; height: 100%;}
        }

    }

</style>