<template>

    <div class="news">
        <h2 class="tab_head">
            <i class="el-icon-document" style="color:#61ae6a;font-weight:600"></i> 新闻公告
            <a href="/news" class="more">更多></a>
        </h2>

        <div class="news_tab">
            <div class="tab_con" 
                v-for="(item,index) in tableData"
                :key="index">
                    <h3 class="news_head">
                        <a :href="'/news/detail?infoid='+item.infoid" 
                            target="_blank" 
                            :title="item.title" 
                            style="display: block; width: 325px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                                {{item.title}}
                        </a>
                    </h3>
            </div>
        </div>
        

    </div>
</template>

<script>
import CONFIG from '@config/config';

import List from '@components/sys/list';
import Card from '@components/sys/card';

export default {
    components: {
        List,
        Card,
    },
    data() {
        return {
            tableData: [],

        };
    },
    methods: {
        queryData() {

            this.$get(`${this.$store.state.api.webUrl}/index/information/getnewsnotice.json`,{
                featurecode:CONFIG.head.planname,
                featureonly:'1'
            }, data => {
                this.tableData = data;
               
            });
        },
       
    },
    mounted() {
        this.queryData();
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .news{
        background: none;
        border: solid 1px #dedede;
        padding: 20px 30px;
        .tab_head{
            width: 100%;
            padding-bottom: 18px;
            font-size: 14px;
            border-bottom:1px solid #dedede;

            .more{
                float: right;
                color: #666;
            }
        }
        .news_tab{
            width: 100%; 
            height: 205px;
            overflow: hidden;
            margin-top:15px;
            .tab_con{
                width: 325px;
                line-height: 36px;
                float: left;
            }
            .news_head{
                font-size: 14px;
                width: auto;
                margin:0;
                font-weight: 400;
                a{
                    color: #666;
                }
                a:hover{
                    color: #61ae6a;
                }
            }
          
         
        }
        .cont_botton{
            display:block;
            width:118px;
            height:44px;
            background: #61ae6a;
            line-height:44px;
            text-align:center;
            margin:0 auto;
            color:#fff;
            margin-top:30px;
        }
        .cont_botton:hover{
            color:#fff;
            opacity: 0.9;
        }
    }
</style>