<template>
    <div class="list">
        <a 
        class="item"
        v-for="(item, index) in data"
        :key="index"
        :href="href(item)"
        target="_blank"
        >
            <div class="addtime">
                {{item[props.time]}}
            </div>

            <div class="title">
                <slot :row="item">
                    {{item[props.title]}}
                </slot>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
        href: {
            type: Function,
            default: (row) => {
                return ``;
            },
        },
        props: {
            type: Object,
            default: () => ({
                title: 'title',
                time: 'time',
            }),
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .list{
        .item {
            display: block;
            overflow: hidden;
            line-height: 50px;
            color: #1b1b1b;
            border-bottom: 1px dashed #a1a2a2;

         

            &:hover{
                color: $primaryColor;
            }

            .title{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: #333333;
                width: 650px;
            }
            .title:hover{
                color: #61ae6a;
            }

            .addtime{
                float: right;
                color: #666666;
            }
        }
    }
</style>