<template>
    <div class="enters">
        <el-row class="item">
            <el-col 
                :span="6" 
                v-for="(item, index) in dataList"
                :key="index">

                    <div class="num">
                        {{item.num}}
                    </div>
                    <div class="text">
                        {{item.title}}
                    </div>

            </el-col>
         
        </el-row>
    </div>
</template>

<script>
import CONFIG from '@config/config';

export default {
    data: () => ({
        dataList: [],

    }),
    created() {
        var that = this;
        this.$get(`${this.$store.state.api.webUrl}/index/information/indexinfoextra.json`, {
            featurecode:CONFIG.head.planname,
            mode:0,
        }, data => {
            that.dataList = [ {
                title: '今日采购公告',
                num: data.jinrijiaoyi.caigougonggaocount,
            },
            {
                title: '今日开标',
                num: data.jinrijiaoyi.kaibiaocount,
            },
            {
                title: '今日评标',
                num: data.jinrijiaoyi.pingbiaocount,
            },
            {
                title: '今日中标',
                num: data.jinrijiaoyi.zhongbiaocount,
            }];
        });
       
    },
}
</script>

<style lang="scss" scoped>
    @import '@css/var.scss';

    .enters{
        height: 100px;
        background-color: #ffffff;
        border: solid 1px #dedede;
        margin: 10px 0;
        .item{
            .el-col{
                text-align: center;
            }
            
            .num{
                margin-top: 10px;
                font-size: 38px;
                font-weight: 600;
                color: #f0a32f;
            }
        }
    }
</style>